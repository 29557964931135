import React, { useEffect } from "react";

import Lottie from "react-lottie";
import * as animationData from "./../../static/json-animate/New Employee.json";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

import RoundedLogoIcon from "../../theme/icons/RoundedLogo";

import AuthContext from "../../store/context/auth-context";
import { ReactComponent as WalletsIcon } from "../../static/svg/wallets.svg";
import { ReactComponent as WalletIcon } from "../../static/svg/wallet.svg";

import { ReactComponent as VisibilityIcon } from "../../static/svg/visibility-grey.svg";
import { ReactComponent as VisibilityOffIcon } from "../../static/svg/visibility-grey-off.svg";
import { ReactComponent as PlusIcon } from "../../static/svg/plus-lg.svg";

import DashboardContext from "../../store/context/dashboard.context";
import { Link, useHistory } from "react-router-dom";
import { EmployeeData } from "../../models/employee.model";
import useHttp from "../../hooks/use-http";
import { getURLWithQueryParams, isEmpty } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";
import WalletStatDesign, {
  WalletStatProps,
} from "../../components/Features/Dashboard/WalletStatDesign";
import EmployeesData from "../../components/Features/Dashboard/Employees";

import MHButton from "../../components/Common/Button/MHButton";
import AccordionDashboard from "../../components/Features/Dashboard/AccordionDashboard";
import EmployeeDialog from "./EmployeeDialog";
import SpendingDesign from "../../components/Features/Dashboard/SpendingDesign";
import { useOnboardForm } from "../../store/context/onboard-context";
import { SubscriptionData } from "../../models/subscription.model";

const WalletBalanceStatsGrid = () => {
  // };

  const { accountDetails } = useOnboardForm();

  // console.log("accountDetails", accountDetails?.bankName);

  const authCtx = React.useContext(AuthContext);

  const { user } = authCtx;
  const { sendHttpRequest } = useHttp();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paydate, setPaydate] = React.useState<string | null>(null);
  const [toggleState, setToggleState] = React.useState(false);

  useEffect(() => {
    const today = new Date();
    const currentDay = today.getDate();

    // Check if today is the 20th day of the month
    if (currentDay >= 20) {
      // Set paydate to the 20th of the next month
      const nextMonth = new Date(today);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      nextMonth.setDate(20);
      setPaydate(nextMonth.toDateString());
    } else {
      // Set paydate to the 20th of the current month
      const currentMonth = new Date(today);
      currentMonth.setDate(20);
      setPaydate(currentMonth.toDateString());
    }
  }, []);

  const handleToggle = () => {
    setToggleState(!toggleState);
    // console.log(toggleState);
  };

  const activeEmployees =
    employees && employees.filter((employee) => employee.status === "ACTIVE");

  const [totalSalary, setTotalSalary] = React.useState<number>(0); // State to hold the total salary

  React.useEffect(() => {
    // Calculate total salary when employees array changes
    const sum =
      activeEmployees &&
      activeEmployees.reduce(
        (acc, employee) =>
          acc +
          (employee.salary +
            employee.Accomodation +
            employee.airtimeOrData +
            employee.meals +
            employee.transportation || 0),
        0
      );
    setTotalSalary(sum);
  }, [activeEmployees]);

  // funded amount should be passed here.
  // This controls the empty state of the Fund Balance
  // Ensure accountDetails is defined and has a balance property
  let currentFund = accountDetails?.balance ?? 0;
  let expensesMonthly = 0;

  const balance =
    currentFund === 0 ? 0 : toggleState === true ? currentFund : "******";

  // const balance =
  //   currentFund === 0
  //     ? 0
  //     : toggleState === true
  //     ? accountDetails.balance
  //     : "******";
  // const balance = toggleState === true ? currentFund : "******"; // Assuming this is a number representing a balance

  const STATS: WalletStatProps[] = [
    {
      theme: "default",
      icon: (
        <RoundedLogoIcon
          sx={{
            p: 0.6,
            mb: 2,
            backgroundColor: currentFund === 0 ? "#EEEEEE" : "#C7D8E4",
          }}
        >
          {currentFund === 0 ? (
            <WalletIcon className="w-6 h-6" />
          ) : (
            <WalletsIcon className="w-6 h-6" />
          )}
        </RoundedLogoIcon>
      ),
      icon2: (
        <RoundedLogoIcon sx={{ backgroundColor: "transparent" }}>
          {toggleState === false ? (
            <VisibilityIcon className="w-6 h-6" />
          ) : (
            <VisibilityOffIcon className="w-6 h-6" />
          )}
        </RoundedLogoIcon>
      ),
      title: "Household Payroll Wallet",
      stat: balance,
      role: "Employer",
      account: 0,
      onClick: handleToggle,
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        className=" place-content-start md:justify-between gap-2 md:gap-0"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={5.5}
          p={{ xs: 1, sm: 4 }}
          // onClick={handleOpen}
          sx={{
            // cursor: "pointer",
            backgroundColor: "#F9F9F7",
            borderRadius: 3,
          }}
          className=" order-2 lg:order-1"
        >
          <AccordionDashboard />
        </Grid>
        {STATS.map((stat) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6.2}
            key={stat.title}
            p={4}
            // onClick={handleOpen}
            sx={{
              // cursor: "pointer",
              backgroundColor: "#F9F9F7",
              borderRadius: 3,
            }}
            className=" order-1 lg:order-2"
          >
            <Box className=" " position={"relative"}>
              <WalletStatDesign {...stat} />
            </Box>
            <SpendingDesign
              recurringAmount={totalSalary}
              expensesAmount={expensesMonthly}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

const Dashboard = () => {
  const authCtx = React.useContext(AuthContext);

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  // console.log("accountDetails", accountDetails?.bankName);

  const { user } = authCtx;
  const { sendHttpRequest } = useHttp();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [openModal, setOpenModal] = React.useState(false);

  const [openEmployee, setOpenEmployee] = React.useState(false);

  const handleOpenEmployee = () => {
    setOpenEmployee(true);
  };

  const handleCloseEmployee = () => {
    setOpenEmployee(false);
  };

  // useEffect(() => {
  //   if (organization?.subscriptionType === "") {
  //     history.push("/organization/subscription");
  //   } else {
  //     history.push("/organization/dashboard");
  //   }
  // }, [organization?.subscriptionType, history]);

  // console.log("subsType", organization?.subscriptionType);
  // console.log("subscriber", subscriber);

  // console.log("organization", (organization));
  // console.log("organization", (organization));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // console.log("Employees", employees);

  const [subscriptionType, setSubscriptionType] = React.useState<
    SubscriptionData[]
  >([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/subscription/plan/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<SubscriptionData[]>) => {
        setSubscriptionType(response.data);
      }
    );
  }, []);

  const filteredSub = subscriptionType
    ? subscriptionType.filter(
        (subs) =>
          subs.name &&
          subs.id &&
          subs?.id === Number(organization?.subscriptionType)
      )
    : [];

  const proSub = filteredSub[0];
  let empLimit = (organization?.numberOfEmployees || 0) >= proSub?.empNo;

  // console.log("numberOfEmployees", organization?.numberOfEmployees);
  // console.log("empLimit", empLimit);
  // console.log("proSub", proSub);

  return (
    <React.Fragment>
      <Box mb={4} p={{ xs: 0, sm: 3, md: 3 }}>
        <Box>
          <Grid
            container
            spacing={2}
            className="flex justify-center align-middle items-center"
          >
            <Grid item xs={12} md={11}>
              <Typography
                variant="h1"
                fontSize={{ xs: "20px", sm: "30px" }}
                align="center"
                mt={3}
                gutterBottom
                display={"flex"}
                alignSelf={"start"}
              >
                Hi, {authCtx.user?.firstName}
              </Typography>
              <Typography
                variant="body2"
                mb={3}
                gutterBottom
                display={"flex"}
                alignSelf={"start"}
                className=" opacity-50"
              >
                Below are your activities overview
              </Typography>

              <WalletBalanceStatsGrid />
            </Grid>

            <Box className=" my-8">
              {isEmpty(employees) ? (
                <Lottie
                  options={defaultOptions}
                  height={"117.4px"}
                  width={"206.97px"}
                />
              ) : null}

              <MHButton
                color="primary"
                sx={{}}
                type="submit"
                className=" w-[280px]"
                startIcon={<PlusIcon />}
                onClick={handleOpenEmployee}
                // onClick={() =>
                //   history.push("/organization/employee-onboarding")
                // }
                disabled={empLimit}
              >
                New Household Employee
              </MHButton>
              <br />
              {empLimit && (
                <>
                  {/* <br /> */}
                  <MuiLink
                    component={Link}
                    underline="always"
                    to="/organization/subscription"
                    // display="block"
                    // align="center"
                    className=" text-[#ff1744] underline pl-2 pt-2"
                  >
                    Kindly upgrade plan to add more employees
                  </MuiLink>
                </>
              )}
            </Box>

            <Grid item xs={12} md={11}>
              <EmployeesData />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <EmployeeDialog open={openEmployee} onClose={handleCloseEmployee} />
      {/* <SubscribeDialog open={openDialog} onClose={handleCloseDialog} /> */}
    </React.Fragment>
  );
};

export default Dashboard;
