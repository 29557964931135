import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { MagazineFeature } from "../Families";
import MHButton from "../../components/Common/Button/MHButton";

type Props = {
  handleReserve?: () => void;
};

export const PicLinkFeature = (props: {
  title: string;
  content: string;
  label: string;
  imageSrc: string;
  inverted?: boolean;
  handleClick?: () => void;
}) => {
  return (
    <Grid
      container
      direction={{
        xs: "column-reverse",
        md: props.inverted ? "row-reverse" : "row",
      }}
      mb={8}
    >
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: "90%", md: "70%" }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "2rem", md: "2.4rem" }}
            width={{ xs: "100%", md: "90%" }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4,
            }}
            paragraph
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "100%", md: "80%" }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={3}
          >
            {props.label}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "100%", md: "80%" }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}
          >
            {props.content}
          </Typography>

          <MHButton
            color="primary"
            sx={{}}
            //   type="submit"
            onClick={props.handleClick}
            className="  "
            // fullWidth
          >
            Reserve Your Seat
          </MHButton>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: "100%", md: "100%" }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          // pr={{ xs: 2, md: 10 }}
          // pl={2}
          sx={{
            "& img": {
              width: "100%",
              [props.inverted ? "pl" : "pr"]: { xs: 2, md: 10 },
              [props.inverted ? "pr" : "pl"]: 2,
              // height: '500px'
            },
          }}
        >
          <img
            src={props.imageSrc}
            alt="WorkLife Dashboard"
            width="800"
            draggable={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const SingleTrainingBody = (props: Props) => {
  return (
    <React.Fragment>
      <Box className=" text-center mt-10 w-[90%] md:w-[90%] mx-auto">
        {/* <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "12px", sm: "12px" }}
          lineHeight={{ xs: "100%", sm: "102%" }}
          width={{ xs: "100px", sm: "369px" }}
          letterSpacing={"0.1em"}
          className="uppercase opacity-50 md:opacity-100 "
        >
          BLESSING ADESIYAN | Founder & CEO, Mother Honestly Group Inc.
        </Typography> */}
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "28px", sm: "38px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize py-4 mb-6 w-[90%] md:w-[700px] text-center mx-auto"
        >
          Professional Training For Nannies In The Modern Nigerian Home
        </Typography>
        <PicLinkFeature
          imageSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/trainNanny.jpeg"
          label="Sept 28th, 2024 - 10:00am - 4:00pm"
          content="In today's fast-paced world, Nigerian families are navigating an
          evolving landscape of childcare and household management.
          Unfortunately, many families have found themselves exposed to subpar
          standards of care. The increasing reports of nannies with little to no
          experience in childcare or housekeeping are concerning. From a lack of
          familiarity with essential household appliances to more severe issues
          of trust and safety—such as petty theft and, tragically, even more
          disturbing crimes—families are finding it increasingly difficult to
          entrust their homes and, most importantly, their children to
          caregivers."
          title="The Baby Lounge VI"
          handleClick={props.handleReserve}
          inverted
        />
        {/* <Box className=" text-center -mt-10 lg:-mt-8" mb={{ sm: 10 }}>
          <Typography
            variant="caption"
            color="primary"
            fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
            className=" opacity-80 place-self-center"
          >
            Date: Sept 28th, 2024
          </Typography>{" "}
          <br />
          <Typography
            variant="caption"
            color="primary"
            fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
            className=" opacity-80 place-self-center"
          >
            Time: 10:00am - 4:00pm
          </Typography>{" "}
          <br />
          <Typography
            variant="caption"
            color="primary"
            fontSize={{ xs: "14px", sm: "14px", md: "16px" }}
            className=" opacity-80 place-self-center"
          >
            Venue: The Baby Lounge VI
          </Typography>{" "}
          <br />
          <Box my={4} width={{ xs: "100%", sm: "200px" }} mx={"auto"}>
            <MHButton
              color="primary"
              sx={{}}
              //   type="submit"
              onClick={props.handleReserve}
              className="  "
              fullWidth
            >
              Reserve Your Seat
            </MHButton>
          </Box>
        </Box> */}
        <PicLinkFeature
          imageSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/Upskill++Your+Nanny.png"
          label="Oct 5th, 2024 - 9:00am - 3:00pm"
          content="For families that expect more from their nannies and household staff,
          this has created an urgent need for change. Many parents desire
          nannies who are not only skilled and knowledgeable but also
          trustworthy, reliable, and equipped to nurture their children while
          supporting the family's goals. These modern Nigerian families are
          searching for solutions that help their household staff transition
          from merely filling a role to being integral members of the family
          support system."
          title="Lo Studios Gymland, VI"
          handleClick={props.handleReserve}
        />
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[100%] md:w-[90%] text-center mx-auto"
        >
          Raise The Standard Of Care is our comprehensive training program,
          designed to meet these challenges head-on. This professional training
          seeks to elevate the level of care within Nigerian homes by equipping
          nannies with the skills, knowledge, and ethics necessary to become
          true childcare and household management professionals. We recognize
          that many caregivers come into homes with low literacy levels, minimal
          caregiving experience, and no knowledge of modern household
          technologies, such as dishwashers, washing machines, or even proper
          child developmental care. This training transforms nannies into
          proficient, confident, and trustworthy caretakers who truly understand
          the weight of their responsibility.
          <br /> <br />
          <br /> <br />
        </Typography>
        <Avatar
          src="https://mh-resources-production.s3.us-west-1.amazonaws.com/For+Caregivers.png"
          variant="square"
          alt="State of Care"
          className="mx-auto pt-8 md:px-6 h-auto w-auto md:w-[80%] md:h-auto lg:w-[80%] lg:h-fit "
        />
        {/* <MagazineFeature
          imageSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/For+Caregivers.png"
          content="is our comprehensive training program, designed to meet these
          challenges head-on. This professional training seeks to elevate the
          level of care within Nigerian homes by equipping nannies with the
          skills, knowledge, and ethics necessary to become true childcare and
          household management professionals. We recognize that many caregivers
          come into homes with low literacy levels, minimal caregiving
          experience, and no knowledge of modern household technologies, such as
          dishwashers, washing machines, or even proper child developmental
          care. This training transforms nannies into proficient, confident, and
          trustworthy caretakers who truly understand the weight of their
          responsibility."
          title=" Raise The Standard Of Care"
        /> */}
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[100%] md:w-[90%] pt-4 text-start mx-auto"
        >
          <br />
          <strong className=" font-areaExt text-base">
            {" "}
            What Families Can Expect From This Training:{" "}
          </strong>
          <br />
          <br />
          1.{" "}
          <strong className=" font-areaBlack">
            Trust & Safety as the Foundation{" "}
          </strong>{" "}
          <br />
          Our program begins by addressing the most critical concerns for modern
          families: trust and safety. Caregivers will undergo rigorous lessons
          on personal accountability, the importance of integrity in the home,
          and how to uphold trust in their role. This module also focuses on
          safeguarding the well-being of children and maintaining a secure
          household environment. <br />
          2.{" "}
          <strong className=" font-areaBlack">
            Professional Childcare Skills{" "}
          </strong>{" "}
          <br />
          From infant care to the emotional, social, and intellectual
          development of older children, our training covers key areas of
          childcare. Nannies will learn how to properly engage with children,
          manage their schedules, support their educational and extracurricular
          activities, and foster a nurturing environment. <br />
          3. <strong className=" font-areaBlack">
            {" "}
            Household Management{" "}
          </strong>{" "}
          <br />
          Many nannies struggle with basic housekeeping skills, from cleaning
          routines to operating household appliances like washing machines and
          dishwashers. Our training provides hands-on guidance in modern
          household management, helping nannies efficiently manage everyday
          tasks and ensuring a clean, well-organized home. <br />
          4.{" "}
          <strong className=" font-areaBlack">
            Ethical Standards and Professionalism{" "}
          </strong>{" "}
          <br />
          Trust is built through behavior and professionalism. We train nannies
          to uphold the highest standards of conduct, ensuring they respect
          boundaries, maintain confidentiality, and honor the expectations set
          by the family. This professionalism extends to handling everyday
          situations with integrity and care. <br />
          5.{" "}
          <strong className=" font-areaBlack">
            Emergency Preparedness and First Aid{" "}
          </strong>{" "}
          <br />
          The safety of your child is paramount, and our training includes
          essential skills in first aid and emergency preparedness. Nannies will
          learn how to respond to medical situations, from minor scrapes to more
          severe emergencies, with confidence and competence.
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[100%] md:w-[90%] pt-4 text-start mx-auto"
        >
          <br />
          <strong className=" font-areaExt text-base">
            {" "}
            Why This Training Matters for Your Home{" "}
          </strong>
          <br />
          <br />
          This training is not just about improving individual skills; it's
          about creating an atmosphere of reliability, care, and peace of mind
          in the modern Nigerian home. By professionalizing your current nanny
          or hiring someone who has gone through this program, you can elevate
          the level of care in your household, protect your family, and give
          your children the nurturing environment they deserve. <br />
          <br />
          We believe that every family deserves the best care, and through
          Raising The Standard Of Care, we are helping families across Nigeria
          achieve that goal. Your home will not just be a place of safety but a
          place of growth, development, and harmony where everyone—including
          your nannies—contributes meaningfully to the wellbeing of the
          household.
          <br /> <br />
          <strong className=" font-areaExt text-base">
            {" "}
            What Participants Receive:{" "}
          </strong>
          <br />
          <br />
          <strong className=" font-areaBlack">
            • Theoretical and Practical Skill Training{" "}
          </strong>{" "}
          Hands-on learning to ensure competence in both caregiving and
          household management. <br />
          <strong className=" font-areaBlack">
            • Comprehensive Childcare Workbook:{" "}
          </strong>{" "}
          A detailed guide to reinforce learning. <br />
          <strong className=" font-areaBlack">
            • Nanny Activity Log & Report (90 pages):{" "}
          </strong>{" "}
          A structured way for nannies to document their activities and report
          progress. <br />
          <strong className=" font-areaBlack">
            • Two Follow-Up Check-ins:{" "}
          </strong>{" "}
          Ongoing support within 30 days post-training to ensure application of
          skills and to address any challenges. <br />
          <strong className=" font-areaBlack">
            • Optional: Caring Blocks Uniform (N50,000):{" "}
          </strong>{" "}
          A professional uniform that reinforces the new standard of care.
        </Typography>
        <br /> <br />
      </Box>
    </React.Fragment>
  );
};

export default SingleTrainingBody;
