import React, { useEffect, useState } from "react";
import { useOnboardForm } from "../../store/context/onboard-context";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { SelectOption } from "@mui/base";

import SnackbarContext from "../../store/context/snackbar.context";
import DashboardContext from "../../store/context/dashboard.context";

import { ReactComponent as ArrowLeftIcon } from "../../static/svg/arrow-left.svg";
import { ReactComponent as CancelIcon } from "../../static/svg/cancel-mark-rounded-lg.svg";

import { formatNumber, resolveErrorMessage } from "../../utils/utils";
import useInput from "../../hooks/use-input";
import * as validators from "../../utils/validators";

import Lottie from "react-lottie";
import * as animationData from "../../static/json-animate/success.json";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import MHButton from "../../components/Common/Button/MHButton";
import usePhoneInput from "../../hooks/use-phone";
import MHPhoneInput from "../../components/Common/Form/MHPhoneInput";
import MHDialog from "../../components/Common/Dialog/MHDialog";
import { HttpResponse } from "../../models/api.interface";
import useHttp from "../../hooks/use-http";
import { TransactionProps } from "../../models/transaction.model";
import useDialog from "../../hooks/use-dialog";
import Label from "../../components/Common/Form/Label";
import useCountdownTimer from "../../hooks/use-countdown-timer";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/context/auth-context";
import MHAutocomplete, {
  Option,
} from "../../components/Common/Form/MHAutocomplete";

interface SingleTrainingDialogProps {
  open: boolean;
  onClose: () => void;
  price?: number | any;
}

const EVENTTYPE: SelectOption<string>[] = [
  {
    value: "The Baby Lounge (Sept 28th, 2024)",
    label: "The Baby Lounge (Sept 28th, 2024)",
  },
  {
    value: "Lo Studios (Oct 5th, 2024)",
    label: "Lo Studios (Oct 5th, 2024)",
  },
];

const InputSubs = (props: SingleTrainingDialogProps) => {
  const { setActiveStep } = useOnboardForm();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const [payInitResponse, setPayInitResponse] =
    React.useState<TransactionProps | null>(null);

  const {
    value: enteredfirstName,
    valid: enteredfirstNameIsValid,
    error: firstnameInputHasError,
    onChange: firstnameInputChangeHandler,
    onBlur: firstnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredlastName,
    valid: enteredlastNameIsValid,
    error: lastnameInputHasError,
    onChange: lastnameInputChangeHandler,
    onBlur: lastnameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: emailInputHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAddress,
    valid: enteredAddressIsValid,
    error: addressInputHasError,
    onChange: addressInputChangeHandler,
    onBlur: addressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEvent,
    valid: enteredEventIsValid,
    error: eventInputHasError,
    onChange: eventInputChangeHandler,
    onBlur: eventInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const eventOptions: Option[] = EVENTTYPE.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check
  const filteredPhoneNumber = phoneNumber?.replace(/\s/g, "");
  const filteredAmount = props.price?.replace(/\s/g, "");

  const formIsValid =
    enteredfirstNameIsValid &&
    enteredlastNameIsValid &&
    enteredEmailIsValid &&
    phoneUseNumberIsValid &&
    enteredAddressIsValid;

  const resetForm = () => {
    firstnameInputChangeHandler("");
    lastnameInputChangeHandler("");
    addressInputChangeHandler("");
    emailInputChangeHandler("");
  };

  const submitBody = {
    firstName: enteredfirstName,
    lastName: enteredlastName,
    EmailAddress: enteredEmail,
    phonenumber: filteredPhoneNumber,
    address: enteredAddress,
    amount: Number(filteredAmount),
  };

  const { loading, error, sendHttpRequest } = useHttp();

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    // emailInputRef.current!.focus();
    event.preventDefault();

    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input all required field",
        variant: "warning",
      });
    }

    // console.log("submitBody", submitBody);

    await sendHttpRequest(
      process.env.REACT_APP_API_BASE_URL + "/account/external/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submitBody),
      },
      (response: HttpResponse<TransactionProps>) => {
        const data = response.data; // Extract the data from response

        // Update state with response data
        setPayInitResponse(data);

        // Safely handle amount conversion
        const amountStr =
          typeof data.amount === "number"
            ? data.amount.toString()
            : data.amount || "";

        // Store values in localStorage
        localStorage.setItem("TRbankName", data.bankName || "");
        localStorage.setItem("TRaccountNumber", data.accountNumber || "");
        localStorage.setItem("TRamount", amountStr); // amountStr should be a string here
        localStorage.setItem("TRcurrency", data.currency || "");
        localStorage.setItem("TRreference", data.reference || "");
        localStorage.setItem("TRcustomerId", String(data.customerId) || "");

        // set info
        localStorage.setItem("TRfirstName", enteredfirstName);
        localStorage.setItem("TRlastName", enteredlastName);
        localStorage.setItem("TRemail", enteredEmail);
        localStorage.setItem("TRevent", enteredEvent);
        localStorage.setItem("TRaddress", enteredAddress);
        localStorage.setItem("TRphoneNumber", filteredPhoneNumber);

        // Update the active step
        setActiveStep(1);
      }
    );
  };

  React.useEffect(() => {
    if (payInitResponse) {
      const {
        bankName = "",
        accountNumber = "",
        amount = 0, // Default to 0 if amount is undefined
        customerId = 0, // Default to 0 if amount is undefined
        currency = "",
        reference = "",
      } = payInitResponse;

      // Convert amount to a string if it's a number
      const amountStr = typeof amount === "number" ? amount.toString() : amount;

      localStorage.setItem("TRbankName", bankName);
      localStorage.setItem("TRaccountNumber", accountNumber);
      localStorage.setItem("TRamount", amountStr); // Use amountStr here
      localStorage.setItem("TRcurrency", currency);
      localStorage.setItem("TRreference", reference);
      localStorage.setItem("TRcustomerId", String(customerId) || "");

      // set info
      localStorage.setItem("TRfirstName", enteredfirstName);
      localStorage.setItem("TRlastName", enteredlastName);
      localStorage.setItem("TRemail", enteredEmail);
      localStorage.setItem("TRevent", enteredEvent);
      localStorage.setItem("TRaddress", enteredAddress);
      localStorage.setItem("TRphoneNumber", filteredPhoneNumber);

      setActiveStep(1);
    }
  }, [payInitResponse]);

  // console.log("payInitResponse", payInitResponse);

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "470px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          fontSize={{ xs: "1rem", sm: "1rem" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Training Enrollment
        </Typography>
      </Box>

      {/* <Box component={"form"} onSubmit={handleInputSubs}> */}
      <Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <Box>
            <Box component={"form"} onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="center">
                <Box width={{ xs: "300px", sm: "350px" }}>
                  <MHFormControl
                    id="fname"
                    type="text"
                    // label="First Name"
                    placeholder="First Name"
                    value={enteredfirstName}
                    onChange={firstnameInputChangeHandler}
                    onBlur={firstnameInputBlurHandler}
                    required
                    // autoFocus
                  />

                  <MHFormControl
                    id="lname"
                    type="text"
                    // label="Last Name"
                    placeholder="Last Name"
                    value={enteredlastName}
                    onChange={lastnameInputChangeHandler}
                    onBlur={lastnameInputBlurHandler}
                    required
                    // autoFocus
                  />

                  <MHFormControl
                    id="email"
                    type="email"
                    // label="Email"
                    placeholder="Email"
                    value={enteredEmail}
                    onChange={emailInputChangeHandler}
                    onBlur={emailInputBlurHandler}
                    required
                    // autoFocus
                  />
                  <Box mb={2}>
                    <MHAutocomplete
                      label=""
                      placeholder="Select Event Type"
                      options={eventOptions}
                      onInputChange={(val) =>
                        eventInputChangeHandler(val as string)
                      }
                    />
                  </Box>

                  <MHPhoneInput
                    value={phoneNumber}
                    onChange={phoneNumberInputChangeHandler}
                    label=""
                    errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                      "Please enter a valid phone number"
                    )}
                  />

                  <MHFormControl
                    id="address"
                    type="address"
                    // label="address"
                    placeholder="Address"
                    value={enteredAddress}
                    onChange={addressInputChangeHandler}
                    onBlur={addressInputBlurHandler}
                    required
                    // autoFocus
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="center">
                <Box width={{ xs: "300px", sm: "350px" }}>
                  <MHButton
                    color="primary"
                    sx={{}}
                    type="submit"
                    loading={loading}
                    className="pt-2  capitalize w-full h-[44px]"
                    fullWidth
                    // startIcon={<PlusIcon />}
                    // onClick={handleSubmit}
                    // onClick={() =>
                    //   history.push("/organization/employee-onboarding")
                    // }
                  >
                    Submit
                  </MHButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ConfirmSubs = (props: SingleTrainingDialogProps) => {
  const { setActiveStep } = useOnboardForm();

  const [payState, setPayState] = useState(false);

  let amount = localStorage.getItem("TRamount");
  let bankName = localStorage.getItem("TRbankName");
  let accountNumber = localStorage.getItem("TRaccountNumber");
  let reference = localStorage.getItem("TRreference");
  let currency = localStorage.getItem("TRcurrency");
  let customerId = localStorage.getItem("TRcustomerId");

  let firstName = localStorage.getItem("TRfirstName");
  let lastName = localStorage.getItem("TRlastName");
  let email = localStorage.getItem("TRemail");
  let event = localStorage.getItem("TRevent");
  let address = localStorage.getItem("TRaddress");
  let phoneNumber = localStorage.getItem("TRphoneNumber");

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const requestBody = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    event: event,
    amount: amount,
    phone: phoneNumber,
    customerId: customerId,
    transRef: reference,
    address: address,
  };

  const closure = () => {
    localStorage.removeItem("TRaccountNumber");
    localStorage.removeItem("TRamount");
    localStorage.removeItem("TRbankName");
    localStorage.removeItem("TRcurrency");
    localStorage.removeItem("TRreference");
    localStorage.removeItem("TRcustomerId");
    localStorage.removeItem("TRfirstName");
    localStorage.removeItem("TRlastName");
    localStorage.removeItem("TRemail");
    localStorage.removeItem("TRphoneNumber");
    localStorage.removeItem("TRaddress");

    setActiveStep(0);
    props.onClose();
  };

  const completedTrans = () => {
    localStorage.removeItem("TRaccountNumber");
    localStorage.removeItem("TRamount");
    localStorage.removeItem("TRbankName");
    localStorage.removeItem("TRcurrency");
    localStorage.removeItem("TRreference");
    localStorage.removeItem("TRcustomerId");
    localStorage.removeItem("TRfirstName");
    localStorage.removeItem("TRlastName");
    localStorage.removeItem("TRemail");
    localStorage.removeItem("TRevent");
    localStorage.removeItem("TRphoneNumber");
    localStorage.removeItem("TRaddress");
  };

  // Sheet API to store in google docs
  const url = `https://sheet.best/api/sheets/dac66002-7166-4535-adf3-ea83b9dca16c`;
  const confirmUrl =
    process.env.REACT_APP_API_BASE_URL + "/tranx/confirm-payment";

  const handleTrainingEnroll = async () => {
    try {
      console.log("requestBody", requestBody);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Handle success
        setActiveStep(2);
        toast({
          message: "Successfully enrolled for training.",
          variant: "success",
        });
        completedTrans();
      } else {
        const errorData = await response.json();
        // console.error("Request submission failed:", errorData);
        // console.log("requestBody", requestBody);
        closure();
        toast({
          message: `${errorData.error}`,
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error enrolling:", error);
    }
  };

  // let verify = true;

  // if (verify === true) {
  //   handleTrainingEnroll();
  // } else {
  //   toast({
  //     message: `Cannot verify payment`,
  //     variant: "error",
  //   });
  // }

  const handlePayState = () => {
    setPayState(false);
  };

  const confirmBody = {
    reference: reference,
    amount: Number(amount),
  };

  const handleInputVerify = async () => {
    setPayState(true);
    try {
      // console.log("requestBody", requestBody);

      const response = await fetch(confirmUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(confirmBody),
      });

      if (response.ok) {
        // Handle success
        handleTrainingEnroll();
        handlePayState();
      } else {
        handlePayState();
        const errorData = await response.json();
        console.error("Request submission failed:", errorData);
        // console.log("confirmBody", confirmBody);
        toast({
          message: "Payment yet to be confirmed, Please try again in a minute",
          variant: "warning",
        });
      }
    } catch (error) {
      handlePayState();
      console.error("Error enrolling:", error);
    }
  };

  const handleBack = () => {
    localStorage.removeItem("TRaccountNumber");
    localStorage.removeItem("TRamount");
    localStorage.removeItem("TRbankName");
    localStorage.removeItem("TRcurrency");
    localStorage.removeItem("TRreference");
    localStorage.removeItem("TRcustomerId");
    localStorage.removeItem("TRfirstName");
    localStorage.removeItem("TRlastName");
    localStorage.removeItem("TRemail");
    localStorage.removeItem("TRphoneNumber");
    localStorage.removeItem("TRaddress");
    setActiveStep(0);
  };

  const { formattedTime } = useCountdownTimer(20);

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "510px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
      sx={{
        overflow: "hidden",
        overscrollBehaviorY: "none",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          fontSize={{ xs: "1rem", sm: "1rem" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Verification
        </Typography>
      </Box>
      <Box display="flex" alignItems="start" justifyContent="start" mt={-3.5}>
        <Button onClick={handleBack} className=" removeUnderline">
          <Typography
            variant="body2"
            fontSize={{ xs: "10px", sm: "12px", md: "14px" }}
            color={"primary"}
            gutterBottom
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <ArrowLeftIcon />
            Back
          </Typography>
        </Button>
      </Box>

      <Box
        className=""
        alignItems={"center"}
        alignContent={"center"}
        alignSelf={"center"}
        display={"grid"}
        mt={3}
      >
        <Box className="" my={1} px={3}>
          <Box width="100%">
            <Box>
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                gutterBottom
                align="left"
                justifyContent="start"
                pl={1}
              >
                Bank Name
              </Typography>

              <Button
                // onClick={copyBankToClipboard}
                disabled
                className="removeUnderline"
                fullWidth
                disableRipple
              >
                <Box
                  className=""
                  width={{ xs: "100%", sm: "100%" }}
                  height={{ xs: "45px", sm: "45px" }}
                  sx={{
                    border: 1,
                    borderColor: "#D9D9D9",
                    borderRadius: 1,
                    px: 2,
                  }}
                  alignItems="center"
                  display="flex"
                  gap={2}
                >
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                    color={"primary"}
                    gutterBottom
                    align="left"
                    justifyContent="start"
                    width={{ xs: "100%", sm: "100%" }}
                    my={1}
                  >
                    {bankName}
                  </Typography>
                </Box>
              </Button>
            </Box>

            <br />

            <Box>
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
                gutterBottom
                align="left"
                justifyContent="start"
                pl={1}
              >
                Account Number
              </Typography>

              <Button
                // onClick={copyAccountToClipboard}
                disabled
                className="removeUnderline"
                fullWidth
                disableRipple
              >
                <Box
                  className=""
                  width={{ xs: "100%", sm: "100%" }}
                  height={{ xs: "45px", sm: "45px" }}
                  sx={{
                    border: 1,
                    borderColor: "#D9D9D9",
                    borderRadius: 1,
                    px: 2,
                  }}
                  alignItems="center"
                  display="flex"
                  gap={2}
                >
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                    color={"primary"}
                    gutterBottom
                    align="left"
                    justifyContent="start"
                    width={{ xs: "100%", sm: "100%" }}
                    my={1}
                  >
                    {accountNumber}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
          <br />
          <Box>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              gutterBottom
              align="left"
              justifyContent="start"
              pl={1}
            >
              Amount
            </Typography>

            <Button
              // onClick={copyAccountToClipboard}
              disabled
              className="removeUnderline"
              fullWidth
              disableRipple
            >
              <Box
                className=""
                width={{ xs: "100%", sm: "100%" }}
                height={{ xs: "45px", sm: "45px" }}
                sx={{
                  border: 1,
                  borderColor: "#D9D9D9",
                  borderRadius: 1,
                  px: 2,
                }}
                alignItems="center"
                display="flex"
                gap={2}
              >
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: "10px", sm: "13px", md: "13px" }}
                  color={"primary"}
                  gutterBottom
                  align="left"
                  justifyContent="start"
                  width={{ xs: "100%", sm: "100%" }}
                  my={1}
                >
                  {currency} {formatNumber(Number(amount))}
                </Typography>
              </Box>
            </Button>
          </Box>

          <br />
          <Typography
            variant="subtitle1"
            color={"#6B6B6B"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            width={{ xs: "100%", sm: "100%" }}
            gutterBottom
            align="center"
            justifyContent="center fullCenter"
          >
            REF: {reference}
          </Typography>

          <Typography
            variant="subtitle1"
            color={"#6B6B6B"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            width={{ xs: "100%", sm: "100%" }}
            gutterBottom
            align="center"
            justifyContent="center"
          >
            Remaining time: {formattedTime}
          </Typography>
        </Box>

        {payState === true ? (
          <Box className="" my={1} px={3}>
            <Box width="100%">
              <Typography
                variant="body1"
                fontStyle="italic"
                color="primary"
                fontSize={{ xs: 12, sm: 12, md: 12 }}
                // my={2}
              >
                Do not close payment window while we confirm payment
              </Typography>
              <Label
                style={{
                  fontFamily: "Area-Normal-Semibold",
                  fontSize: 13,
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                Please wait while we confirm your payment
                <CircularProgress size={30} color="primary" />
              </Label>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box display="flex" justifyContent="center">
              <Box my={3} width={{ xs: "300px", sm: "350px" }}>
                <Typography
                  variant="body1"
                  fontStyle="italic"
                  color="primary"
                  fontSize={{ xs: 10, sm: 12, md: 12 }}
                  // mt={4}
                  align="center"
                  className="mx-auto"
                >
                  Do not click below if payment has not been completed
                </Typography>
                <MHButton
                  color="primary"
                  sx={{}}
                  //   type="submit"
                  onClick={handleInputVerify}
                  className="  "
                  fullWidth
                >
                  Confirm Payment
                </MHButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SuccessSubs = (props: SingleTrainingDialogProps) => {
  let history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleClose = () => {
    props.onClose();
    history.push("/");
  };

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "420px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        mt={5}
      >
        <Box className="w-fit">
          <Lottie options={defaultOptions} />
        </Box>
      </Box>
      <Box display="flex" alignItems="start" justifyContent="center">
        <Typography
          variant="h1"
          fontSize={{ xs: "18px", sm: "18px" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
          ml={2}
        >
          Training Enrollment Complete
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={4} width={{ xs: "100%", sm: "350px" }}>
          <Typography
            variant="subtitle2"
            fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
            color={"primary"}
            gutterBottom
            align="center"
            justifyContent="center"
            my={3}
          >
            Thank you for enrolling for this training. Feel free to navigate our
            Care-Ecosystem
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={3} width={{ xs: "100%", sm: "350px" }}>
          <MHButton
            color="primary"
            sx={{}}
            //   type="submit"
            onClick={handleClose}
            className="  "
            fullWidth
          >
            Go To Homepage
          </MHButton>
        </Box>
      </Box>
    </Box>
  );
};
const FailedSubs = (props: SingleTrainingDialogProps) => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const handleFailure = () => {
    toast({
      message: "Failed to subscribe",
      variant: "error",
    });
  };

  const closure = () => {
    props.onClose();
  };

  return (
    <Box
      mb={0.6}
      height={{ xs: "full", sm: "420px" }}
      width={{ xs: "full", sm: "400px" }}
      className=" ml-0 mr-0 place-content-center items-center mx-auto "
    >
      <Box
        display="flex"
        alignItems="center"
        // alignSelf="center"
        // alignContent="center"
        justifyContent="center"
        my={4}
      >
        <Box className="w-fit">
          <CancelIcon width={100} height={100} />
        </Box>
      </Box>
      <Box display="flex" alignItems="start" justifyContent="center">
        <Typography
          variant="h1"
          fontSize={{ xs: "18px", sm: "18px" }}
          align="center"
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Training Enrollment Failed
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box my={4} width={{ xs: "100%", sm: "350px" }}>
          <Typography
            variant="subtitle2"
            fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
            color={"primary"}
            gutterBottom
            align="center"
            justifyContent="center"
            my={3}
          >
            Something went wrong, please go back and try again or contact Caring
            Blocks associate for assistance if issue persists.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const SingleTrainingDialog: React.FC<SingleTrainingDialogProps> = ({
  open,
  onClose,
  price,
}) => {
  const { activeStep, setActiveStep } = useOnboardForm();

  const closure = () => {
    onClose();
  };

  const handlePayFlow = () => {
    if (activeStep === 0) {
      return <InputSubs price={price} open={open} onClose={onClose} />;
    } else if (activeStep === 1) {
      return <ConfirmSubs price={price} open={open} onClose={closure} />;
    } else if (activeStep === 2) {
      return <SuccessSubs open={open} onClose={closure} />;
    } else if (activeStep === 3) {
      return <FailedSubs open={open} onClose={closure} />;
    }
    return null;
  };

  return (
    <MHDialog
      open={open}
      title={` `}
      handleClose={closure}
      maxWidth="md"
      // actions={}
      scroll="paper"
      style={{ zIndex: 1049 }}
    >
      {handlePayFlow()}
    </MHDialog>
  );
};

export default SingleTrainingDialog;
