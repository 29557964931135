import { Box, Button, Typography } from "@mui/material";
import React from "react";

import useInput from "../../hooks/use-input";
import * as validators from "../../utils/validators";
import { HouseholdRolesLanding } from "../../utils/contracts";
import { useLocation } from "react-router-dom";
import MHAutocomplete, {
  Option,
} from "../../components/Common/Form/MHAutocomplete";
import MHButton from "../../components/Common/Button/MHButton";
import { formatAmount } from "../../utils/utils";

type InstituteHeaderProps = {
  onRoleChange: (role: string) => void;
  title?: string;
  content?: string;
  date?: string;
  time?: string;
  note?: string;
  cost?: string;
  venue?: string;
  handelReserve?: () => void;
};

export const InstituteHeader = ({
  onRoleChange,
  title,
  content,
  date,
  time,
  note,
  venue,
  cost,
  handelReserve,
}: InstituteHeaderProps) => {
  const [selectedRole, setSelectedRole] = React.useState("");

  const location = useLocation();
  let currentPath = location.pathname;

  const householdRolesOptions: Option[] = HouseholdRolesLanding.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    error: enteredRoleHasError,
    onChange: roleInputChangeHandler,
    onBlur: roleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  React.useEffect(() => {
    setSelectedRole(enteredRole);
    onRoleChange(enteredRole);

    console.log("selectedRole", selectedRole);
  }, [enteredRole]);

  let CBcost = Number(cost) * 0.8;

  return (
    <React.Fragment>
      {currentPath.includes("institute") ? (
        <Box
          height={{ xs: "500px", sm: "500px" }}
          bgcolor={"#F9F9F7"}
          position="relative"
          className=" overflow-hidden"
        >
          <Box
            width={{ xs: "full", sm: "497px" }}
            height={{ xs: "400px", sm: "400px" }}
            className=" md:place-content-start place-content-center md:ml-10 lg:ml-20 md:my-14 lg:my-20 my-10"
            zIndex={10}
            display="grid"
            alignSelf="center"
            alignItems="center"
            alignContent="center"
          >
            <Typography
              variant="caption"
              color={"primary"}
              fontSize={{ xs: "20px", sm: "26px", md: "31px" }}
              width={{ xs: "300px", sm: "350px", md: "497px" }}
              lineHeight={{ xs: "40px", sm: "40px", md: "53px" }}
              textAlign={{ xs: "center", sm: "start" }}
              letterSpacing={"0.01em"}
              className=" mb-2 capitalize"
            >
              {title}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              width={{ xs: "300px", sm: "300px", md: "421px" }}
              textAlign={{ xs: "center", sm: "start" }}
              color="primary"
              gutterBottom
              display={"flex"}
            >
              {content}
            </Typography>

            <Box
              display={{ xs: "grid", sm: "grid", md: "flex" }}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "start" }}
              gap={{ xs: 0, sm: 3 }}
              mx={{ xs: "auto", sm: "0px" }}
            >
              <Box width={{ xs: "250px", sm: "200px", md: "269px" }} mt={2}>
                <MHAutocomplete
                  label=""
                  placeholder="Filter by role"
                  options={householdRolesOptions}
                  onInputChange={(val) => roleInputChangeHandler(val as string)}
                />
              </Box>
            </Box>
          </Box>
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caringBlocks/trainingHeader.webp"
            alt=""
            draggable={false}
            className=" md:h-[600px] lg:h-[650px] xl:h-[700px] z-10 absolute md:-top-8 lg:-top-14 -right-24 lg:-right-4 xl:right-20 hidden md:block"
          />
          {/* <TrainingHeaderBg className=" z-10 absolute md:-top-10 -right-24 lg:right-20 hidden md:block" /> */}
        </Box>
      ) : (
        <Box
          height={{ xs: "450px", sm: "500px" }}
          bgcolor={"#F9F9F7"}
          position="relative"
          className=" overflow-hidden"
        >
          <Box
            width={{ xs: "full", sm: "497px" }}
            height={{ xs: "full", sm: "400px" }}
            className=" md:place-content-start align-middle place-content-center ml-0 mr-0 md:ml-10 lg:ml-20 md:my-14 lg:my-20 my-10"
            zIndex={10}
            p={{ xs: 2, sm: 0, md: 0 }}
            display="grid"
            alignSelf="center"
            alignItems="center"
            alignContent="center"
          >
            <Typography
              variant="caption"
              color={"primary"}
              fontSize={{ xs: "20px", sm: "26px", md: "31px" }}
              width={{ xs: "80%", sm: "350px", md: "350px" }}
              // lineHeight={{ xs: "40px", sm: "40px", md: "53px" }}
              textAlign={{ xs: "center", sm: "start" }}
              letterSpacing={"0.01em"}
              className=" mb-2 capitalize place-self-center md:place-self-start"
            >
              {title}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
              // lineHeight={{ xs: "29px", sm: "29px" }}
              width={{ xs: "80%", sm: "300px", md: "450px" }}
              textAlign={{ xs: "center", sm: "start" }}
              color="primary"
              gutterBottom
              display={"flex"}
              className=" mb-2 place-self-center md:place-self-start"
            >
              {content}
            </Typography>
            <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              className=" opacity-80 place-self-center md:place-self-start"
            >
              Training Fee: {formatAmount(Number(cost))}{" "}
            </Typography>
            {/* <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              className=" opacity-80 place-self-center md:place-self-start"
            >
              Caring Blocks Subscribers: 20% Discount
            </Typography> */}

            <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              className=" opacity-80 place-self-center md:place-self-start"
            >
              Date: {date}
            </Typography>
            {/* <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              className=" opacity-80 place-self-center md:place-self-start"
            >
              Time: {time}
            </Typography>

            <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "10px", sm: "10px", md: "12px" }}
              className=" opacity-80 place-self-center md:place-self-start"
            >
              Venue: {venue}
            </Typography> */}

            <Typography
              variant="body1"
              fontStyle="italic"
              color="primary"
              fontSize={{ xs: "12px", sm: "12px", md: "14px" }}
              className=" place-self-center md:place-self-start"
              mt={2}
            >
              {note}
            </Typography>

            <Box my={4} width={{ xs: "100%", sm: "200px" }}>
              <MHButton
                color="primary"
                sx={{}}
                //   type="submit"
                onClick={handelReserve}
                className="  "
                fullWidth
              >
                Reserve Your Seat
              </MHButton>
            </Box>
          </Box>
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caringBlocks/trainingHeader.webp"
            alt=""
            draggable={false}
            className=" md:h-[600px] lg:h-[650px] xl:h-[700px] z-10 absolute md:-top-8 lg:-top-14 -right-24 lg:-right-4 xl:right-20 hidden md:block"
          />
          {/* <TrainingHeaderBg className=" z-10 absolute md:-top-10 -right-24 lg:right-20 hidden md:block" /> */}
        </Box>
      )}
      {/* </Box> */}
    </React.Fragment>
  );
};
